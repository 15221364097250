import GpsService from "../../services/gpsService";
import SchedulerConfig from './scheduler/SchedulerConfig';

GpsService.getCurrentLocation().then(gpsLocation => console.log(gpsLocation));

const appsConfigs = [
	SchedulerConfig
];

export default appsConfigs;
