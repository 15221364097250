/* eslint-disable object-shorthand */
/* eslint-disable no-var */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import {setUserData} from './userSlice';
import {loginSuccess} from './loginSlice';

export const userLogin = createAsyncThunk('auth/user/userLogin', async (request, thunkApi) => {
	const payload = {
		token: request.token,
		userId: request.userId
	};
	try {
		const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/api/${request.key}/login`, payload);
		const data = await response.data;
		thunkApi.dispatch(showMessage({ message: 'User loggedIn Successfully', variant: 'success' }));
		// thunkApi.dispatch(updateLoggedUserData(data));
		return data;
	} catch (err) {
		thunkApi.dispatch(showMessage({ message: 'Something went wrong. please try again!', variant: 'error' }));
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});

export const getCurrentUser = createAsyncThunk('auth/user/getCurrentUser', async (params, thunkApi) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_AUTH_URL}/api/${process.env.REACT_APP_AUTH_KEY}/users/current`
		);
		const data = await response.data;
		if (data) {
			thunkApi.dispatch(setUserData(data));
			thunkApi.dispatch(loginSuccess());
		}
		return data;
	} catch (err) {
		return console.log(err);
	}
});
