// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import 'typeface-roboto';
import './i18n';
import './react-chartjs-2-defaults';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';
import { Provider } from 'react-redux'
import App from 'app/App';
import {LicenseManager} from 'ag-grid-enterprise';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import store from './app/store';

LicenseManager.setLicenseKey(
	'CompanyName=Qubryx LLC,LicensedGroup=qub-digital-products,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-014975,ExpiryDate=4_May_2022_[v2]_MTY1MTYxODgwMDAwMA==d0be905c9f76707e70e75d06df8514c0'
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
