import process from 'process';
const SERVER_LANG = {
	Java: 'java',
	Node: 'node'
};

export const BACKEND_SUREVUE_URL =
	process.env.REACT_APP_BACKEND_SERVER_LANG === SERVER_LANG.Node
		? process?.env?.REACT_APP_SUREVUE_API_URL_NODE
		: process?.env?.REACT_APP_SUREVUE_API_URL_JAVA;

export const BACKEND_SCHEDULER_URL =
	process.env.REACT_APP_BACKEND_SERVER_LANG === SERVER_LANG.Node
		? process?.env?.REACT_APP_SCHEDULER_API_URL_NODE
		: process?.env?.REACT_APP_SUREVUE_API_URL_JAVA;

// export const BACKEND_SCHEDULER_URL = 'http://localhost:3005'
