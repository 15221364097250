import React from 'react';
import {Redirect} from 'react-router';
import authRoles from '../../../auth/authRoles';

const SchedulerConfig = {
	settings: {
		layout: {
			style: 'layout2',
			scroll: 'content',
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		/*
		{
			path: '/main/request-analysis',
			component: React.lazy(() => import('./request-analysis/RequestAnalysisApp')),
		},
		*/
		{
			path: '/main/task-board',
			component: React.lazy(() => import('./task-board/index'))
		}
	]
};

export default SchedulerConfig;
