const locale = {
	APPLICATIONS: 'Applications',
	LOCATIONS: 'Locations',
	EQUIPMENT: 'Equipment',
	FILE_MANAGER: 'File Manager',
	ALLOCATION_MANAGER: 'Allocation Manager',
	REQUEST_ANALYSIS:'Request Analysis'
};

export default locale;
