import FuseSearch from '@fuse/core/FuseSearch';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/layouts/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/layouts/shared-components/UserMenu';
import clsx from 'clsx';
import { memo,useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import ToggleThemeColor from '../../shared-components/ToggleThemeColor';


const useStyles = makeStyles(theme => ({
	root: {}
}));

function ToolbarLayout1(props) {
	const {taskboardHeader} = useSelector(({schedulerCommon}) => schedulerCommon.schedulerCommon);
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const toolbarTheme = useSelector(selectToolbarTheme);
	const classes = useStyles(props);
//settings/setDefaultSettings
	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
				position="static"
			>
				<Toolbar className="p-0 min-h-48 md:min-h-64 flex justify-between">
					<div className="flex px-16 items-center">
						{config.navbar.display && config.navbar.position === 'left' && (
							<>
								<Hidden mdDown>
									{(config.navbar.style === 'style-3' || config.navbar.style === 'style-3-dense') && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}

									{config.navbar.style === 'style-1' && !navbar.open && (
										<NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
									)}
								</Hidden>

								<Hidden lgUp>
									<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
								</Hidden>
							</>
						)}

						{/* <Hidden mdDown>
							<FuseShortcuts />
						</Hidden> */}
						<Typography className="ml-20 text-xl">{taskboardHeader}</Typography>
					</div>
					<div className="flex items-center px-8 h-full overflow-x-auto">
						{/* <LanguageSwitcher /> */}
						<AdjustFontSize />
						{/*<ToggleThemeColor/>*/}
						{/* <FullScreenToggle />
						<FuseSearch /> */}

						{/* <QuickPanelToggleButton /> */}

						<UserMenu />
					</div>

					{config.navbar.display && config.navbar.position === 'right' && (
						<>
							<Hidden mdDown>
								{!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
							</Hidden>

							<Hidden lgUp>
								<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
							</Hidden>
						</>
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default memo(ToolbarLayout1);
