import { Observable } from 'redux';
import * as Rx from 'rxjs';
import { GPSLocation } from './gpsLocation';

const watchPositionSubject = new Rx.ReplaySubject() < GPSLocation > 1;

const GPS_OPTIONS: GeolocationOptions = {
	enableHighAccuracy: false,
	maximumAge: 5 * 60 * 1000,
	timeout: 5 * 10000,
  };

export class GpsLocationService {
	distanceBetweenTwoGPSLocations = (gpsLocation1: IGPSLocation, gpsLocation2: IGPSLocation): number => {
		const R = 6371; // km (change this constant to get miles)
		const dLat = ((gpsLocation2.latitude - gpsLocation1.latitude) * Math.PI) / 180;
		const dLon = ((gpsLocation2.longitude - gpsLocation1.longitude) * Math.PI) / 180;
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos((gpsLocation1.latitude * Math.PI) / 180) *
				Math.cos((gpsLocation2.latitude * Math.PI) / 180) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return R * c;
	};

	watchPosition = (): Observable<GPSLocation> => {
		return watchPositionSubject.asObservable();
	};

	getCurrentLocation = (enableHighAccuracy: boolean = false): Promise<GPSLocation> => {
		GPS_OPTIONS.enableHighAccuracy = enableHighAccuracy;
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						try {
							const gpsLocation = new GPSLocation(position);
							resolve(gpsLocation);
						} catch (e) {
							reject(e);
						}
					},
					err => {
						reject(err);
					},
					GPS_OPTIONS
				);
			} else {
				console.log('No support for geolocation');
				reject();
			}
		});
	};
}

const instance = new GpsLocationService();

export default instance;
