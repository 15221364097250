import React,{memo,useState} from 'react';
import { setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const options = ['default', 'dark'];
const useStyles = makeStyles(theme => ({
    toggleWrapper: {
        background: "#424242",
        color: 'white',
        padding: '4px',
        borderRadius: '23px',
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "140px"
    },
    toggleButton: {
        padding: "5px 15px",
        borderRadius: '16px',
        flexGrow: 1,
        textTransform: 'capitalize'
    }
})
)


function ToggleThemeColor() {
const classes = useStyles();
const dispatch = useDispatch();
const current = useSelector(({ fuse }) => fuse.settings.current);
const theme = useSelector(({ fuse }) => fuse.settings.current.theme.main )
const [currentTheme, themeToggle] = useState(theme);

const setvalue = (value) =>{
    themeToggle(value)
    const newSettings = {
        ...current,
        theme : {
            main : value === 'dark' ? 'defaultDark' : value,
            navbar: current.theme.navbar,
			toolbar: current.theme.toolbar,
			footer: current.theme.footer
        }
    }
    dispatch(setDefaultSettings(newSettings));
}
	return (
		<>
			<div className={classes.toggleWrapper}>
				
                {options.map(dt => (
					<button type="button" 
                    key={dt}
                    className={classes.toggleButton}
                    onClick={()=>setvalue(dt)} 
                    style={{
                        background: dt === currentTheme ? "#4EAEEC" : "transparent",
                    }}>{dt}</button>
				))}
				
			</div>
		</>
	);
}

export default memo(ToggleThemeColor);
