import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {CLIENTS_COMMENTS} from './urls';
// eslint-disable-next-line import/no-cycle
import { getTasks } from './taskApi';
import { getTaskClient } from './taskCustomerApi';
import { setReloadGrid } from '../taskBoardGridSlice';

export const getClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getClientComments',
	async (params, thunkApi) => {
		try {
			const clientComments = (
				await axios.get(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.clientId}/comments`)
			).data;
			return {clientId: params.clientId, notes: clientComments};
		} catch (err) {
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const addClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/addClientComments',
	async (params, thunkApi) => {
		try {
			const {clientId, ...rest} = params;
			await axios.post(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${clientId}/comments`, rest);
			thunkApi.dispatch(showMessage({message: 'Client Note created successfully', variant: 'success'}));
			await thunkApi.dispatch(getClientComments({clientId})).then(async ()=>{
				await thunkApi.dispatch(getTaskClient());
				thunkApi.dispatch(getTasks());
				thunkApi.dispatch(setReloadGrid());
			});
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateClientComments',
	async (params, thunkApi) => {
		try {
			await axios.put(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.clientId}/comments/${params.id}`, params);
			thunkApi.dispatch(showMessage({message: 'Client Note updated successfully', variant: 'success'}));
			thunkApi.dispatch(getClientComments({clientId: params.clientId}));
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteClientComments',
	async (params, thunkApi) => {
		try {
			await axios.delete(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.clientId}/comments/${params.id}`);
			thunkApi.dispatch(showMessage({message: 'Client Note deleted successfully', variant: 'success'}));
			await thunkApi.dispatch(getClientComments({clientId: params.clientId})).then(async ()=>{
				await thunkApi.dispatch(getTaskClient());
				thunkApi.dispatch(setReloadGrid());
			});
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);
