import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {getLocations, getTechnicians} from './api/schedulerCommonApi';
import {getStateCounties} from './api/stateCountyService';
import { getTaskMasterData } from '../task-board/store/api/taskApi';
import { getTaskLocations } from '../task-board/store/api/taskLocationApi';
import { getTaskClient } from '../task-board/store/api/taskCustomerApi';

const schedulerCommonSlice = createSlice({
	name: 'schedulerCommon',
	initialState: {
		masterLocations: [],
		locations: [],
		technicians: [],
		stateCounties: [],
		gridSearchTerm: '',
		appBarModuleTitle: '',
        gridFilterOptions: {
            locName: [],
            clientName: [],
            status: ['Assigned', 'Completed', 'Incomplete', 'New'],
            recurringType: ['Monthly', 'Weekly', 'Yearly'],
            frequencyTag: ['Annual', 'Bi-Monthly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Semi-Annual', 'Weekly'],
            assignedUsers: [],
            isRecurringTask: ['Yes','No'],
            isActive: ['Yes','No'],
            taskType: [],
            // dueDate: '',
        }
	},
	reducers: {
		setGridSearchTerm: (state, action) => {
			state.gridSearchTerm = action.payload;
		},
		setAppBarModuleTitle: (state, action) => {
			state.appBarModuleTitle = action.payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getTechnicians.fulfilled, (state, action) => {
				const techniciansList = action.payload?.map(val=>val.technicianFullName)?.sort();
				state.technicians = action.payload;
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					assignedUsers: ['Unassigned',...techniciansList, 'Third Party']
				}
			})
			.addCase(getLocations.fulfilled, (state, action) => {
				state.locations = action.payload;
			})
			.addCase(getStateCounties.fulfilled, (state, action) => {
				state.stateCounties = action.payload;
			})
			.addCase(getTaskMasterData.fulfilled, (state, action) => {
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					taskType: _.uniq(action.payload?.filter(val=>val.taskType)?.map(val=>val.taskType)?.sort())
				}
			})
			.addCase(getTaskLocations.fulfilled, (state, action) => {
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					locName: _.uniq(action.payload?.filter(val=>val.locName)?.map(val=>val.locName)?.sort())
				}
			})
			.addCase(getTaskClient.fulfilled, (state, action) => {
				const clientNameList = _.uniq(action.payload?.filter(val=>val.clientName)?.map(val=>val.clientName)?.sort());
				state.gridFilterOptions = {
					...state.gridFilterOptions,
					clientName: ['Unassigned',...clientNameList]
				}
			});
	}
});

export const {setGridSearchTerm, setAppBarModuleTitle} = schedulerCommonSlice.actions;

export default schedulerCommonSlice.reducer;
