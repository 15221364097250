import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {RECURRING_CONFIG_PAGINATED, REOCCURRING_CONFIG} from './urls';
import {processReoccurringConfig} from './processTaskItems';
// eslint-disable-next-line import/no-cycle
import { setReloadGrid } from '../taskBoardGridSlice';

export const getAllReoccurring = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getAllReoccurring',
	async (params, thunkApi) => {
		try {
			const reoccurring = (await axios.get(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}`)).data;
			const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
			const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
			const newTasks = processReoccurringConfig(reoccurring, technicians, clients);
			return newTasks;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getReoccurring = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getReoccurring',
	async (params, thunkApi) => {
		try {
			const {data: reoccurringData, metaData} = (
				await axios.get(`${BACKEND_SCHEDULER_URL}${RECURRING_CONFIG_PAGINATED}`, {params})
			).data;
			const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
			const clients = thunkApi.getState()?.taskBoardApp?.taskBoardGrid?.taskCustomers;
			const newTasks = processReoccurringConfig(reoccurringData, technicians, clients);
			return {tasks: newTasks, metaData};
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getReoccurringById = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getReoccurringById',
	async (reccId, thunkApi) => {
		try {
			const task = (await axios.get(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}/${reccId}`)).data;
			return task;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const createReoccurring = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createReoccurring',
	async (params, thunkApi) => {
		try {
			const task = (await axios.post(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}`, params)).data;
			// await thunkApi.dispatch(getReoccurring());
			// thunkApi.dispatch(getAllReoccurring());
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(showMessage({message: 'Reoccurring config created successfully', variant: 'success'}));
			return task;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateReoccurring = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateReoccurring',
	async (params, thunkApi) => {
		try {
			const task = (await axios.put(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}/${params.id}`, params)).data;
			const technicians = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.technicians;
			thunkApi.dispatch(showMessage({message: 'Reoccurring config updated successfully', variant: 'success'}));
			const updatedTask = processReoccurringConfig([task], technicians);
			// thunkApi.dispatch(getReoccurring());
			// thunkApi.dispatch(getAllReoccurring());
			thunkApi.dispatch(setReloadGrid());
			return updatedTask[0];
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteReoccurring = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteReoccurring',
	async (configId, thunkApi) => {
		try {
			const response = await axios.delete(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}/${configId}`);
			let id = null;
			if (response.status === 200) {
				id = configId;
				// await thunkApi.dispatch(getReoccurring());
				// thunkApi.dispatch(getAllReoccurring());
				thunkApi.dispatch(setReloadGrid());
				thunkApi.dispatch(
					showMessage({message: 'Reoccurring Config deleted Successfully', variant: 'success'})
				);
			}
			return id;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateReoccurringStatus = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateReoccurringStatus',
	async (params, thunkApi) => {
		try {
			await axios.put(`${BACKEND_SCHEDULER_URL}${REOCCURRING_CONFIG}/${params.id}/status`, params);
			// thunkApi.dispatch(getReoccurring());
			// thunkApi.dispatch(getAllReoccurring());
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(
				showMessage({
					message: `${params.isActive ? 'Enabled' : 'Disabled'} Reoccurring Successfully`,
					variant: 'success'
				})
			);
			return params;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);
