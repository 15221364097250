export interface TechniciansMainInfo {
	technicianEmail: string;
	technicianFullName: string;
	technicianUserId: string;
}

export interface Technicians extends TechniciansMainInfo{
	accountId: string;
	clientName: string;
	projects: [];
	roles: [];
}

export const mapFilteredTechnicians = (filterTechData) => {
	return {
		technicianUserId: filterTechData.technicianUserId,
		technicianEmail: filterTechData.technicianEmail,
		technicianFullName: filterTechData.technicianFullName
	};
};


export class TechniciansService {
	mapTechData = async (technicians): Technicians[] => {
		const mappedTech: Technicians[] = [];
		technicians.map((tech) => {
			const obj: mappedTech = {
				technicianEmail: tech.email,
				technicianFullName: tech.name,
				technicianUserId: tech.id,
				accountId: tech.accountId,
				clientName: tech.clientName,
				projects: tech.projects,
				roles: tech.roles,
			}
			mappedTech.push(obj);
			return tech;
		});
		return mappedTech;
	};
}

const instance = new TechniciansService();

export default instance;
