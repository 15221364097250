import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL, BACKEND_SUREVUE_URL} from 'app/main/constants/serverConstants';
import type {MixedTypeLocation, PreLocationDetails, UniqueLocationDetails} from './locationService';
import locationService from './locationService';
import techniciansService from './techniciansService';

const timestamp = new Date().getTime();


export const getTechnicians = createAsyncThunk(
	'requestAnalysisApp/requestAnalysis/getTechnicians',
	async (params, thunkApi) => {
		try {
			const response = await axios.get(`${BACKEND_SCHEDULER_URL}/api/remote/v3/technicians`, {
				params: {cached: false, t: timestamp}
			});
			const data = await response.data;
			return techniciansService.mapTechData(data);
		} catch (err) {
			console.warn(err);
			thunkApi.dispatch(showMessage({message: err.message, variant: 'error'}));
			return thunkApi.rejectWithValue(`Something went wrong. Please try again!`);
		}
	}
);

export const getLocations = createAsyncThunk(
	'requestAnalysisApp/requestAnalysis/getLocations',
	async (params, thunkApi) => {
		try {
			const uniqueLocations: UniqueLocationDetails[] = (
				await axios.get(`${BACKEND_SUREVUE_URL}/api/remote/v3/locations`, {
					params: {
						cached: false,
						t: timestamp
					}
				})
			).data;
			const preLocations: PreLocationDetails[] = (
				await axios.get(`${BACKEND_SUREVUE_URL}/api/remote/v3/pre-locations`, {
					params: {
						cached: false,
						t: timestamp
					}
				})
			).data;
			await uniqueLocations.map(unique => {
				unique.uniqueLocation = true;
				unique.id = unique.uniqueLocationId;
				return unique;
			});
			await preLocations.map(pre => {
				pre.uniqueLocation = false;
				return pre;
			});
			const data: MixedTypeLocation[] = await [].concat(uniqueLocations, preLocations);
			return await locationService.initLocations(data);
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

