import {createAsyncThunk} from "@reduxjs/toolkit";
import { BACKEND_SUREVUE_URL } from "app/main/constants/serverConstants";
import axios from "axios";
import {showMessage} from "../../../../../store/fuse/messageSlice";

export interface StateCounties {
	id: string;
	counties: string[];
	state: string;
}


export class StateCountyService {
}


export const getStateCounties = createAsyncThunk(
	'requestAnalysisApp/requestAnalysis/getStateCounties',
	async (params, thunkApi) => {
		try {
			const response = await axios.get(`${BACKEND_SUREVUE_URL}/api/remote/v3/locations/states`);
			const data: StateCounties[] = await response.data;
			return data;
		} catch (err) {
			console.warn(err);
			thunkApi.dispatch(showMessage({message: 'Something went wrong. please try again!', variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

const instance = new StateCountyService();

export default instance;
