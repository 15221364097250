export interface IGPSLocation {
    latitude: number;
    longitude: number;
    altitude?: number;
}

export class GPSLocation implements IGPSLocation {
    latitude: number;

    longitude: number;

    altitude: number;

    constructor(geoPosition: IGPSLocation) {
        const geoPositionCasted: IGPSLocation = geoPosition;
        if (!!geoPositionCasted && !!geoPositionCasted.coords) {
            this.latitude = geoPositionCasted.coords.latitude;
            this.longitude = geoPositionCasted.coords.longitude;
            this.altitude = geoPositionCasted.coords.altitude;
        } else {
            console.warn("error getting geoPosition");
        }
    }

}
