import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { memo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Navigation from 'app/layouts/shared-components/Navigation';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import QuickPanelToggleButton from '../../shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from '../../shared-components/UserMenu';
import Logo from '../../shared-components/Logo';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary
	}
}));

function NavbarLayout4(props) {
	const classes = useStyles(props);

	return (
		<AppBar
			id='fuse-toolbar'
			className={clsx(classes.root, 'flex relative z-20 shadow-md', props.className)}
			color='default'
		>
			<Toolbar className='container p-0 lg:px-24 min-h-48 md:min-h-64'>
				<div className={clsx(classes.reactBadge, 'react-badge flex items-center py-6 px-6 rounded')}>
					<img className='logo-icon' src='assets/images/logos/spl-logo-only.png' alt='logo' />
				</div>
				<div className={clsx('flex flex-shrink-0 items-center')}>
					<Logo />
				</div>
				<div className={clsx('flex flex-auto items-center w-full h-full container px-16 lg:px-24')}>
					<FuseScrollbars className='flex h-full items-center'>
						<Navigation className='w-full' layout='horizontal' dense />
					</FuseScrollbars>
				</div>
				<div className='flex '>
					<AdjustFontSize />

					<FullScreenToggle />

					<QuickPanelToggleButton />

					<UserMenu />
				</div>
			</Toolbar>
		</AppBar>

	);
}

export default memo(NavbarLayout4);
