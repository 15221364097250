export const RequestStatus = {
	Completed: 'Completed',
	Canceled: 'Canceled',
	Collected: 'Collected',
	InProgress: 'In Progress',
	NotStarted: 'Not Started',
	NotCollected: 'Not Collected',
	DroppedOff: 'Dropped Off',
	LoggedIn: 'Logged In',
	Analyzed: 'Analyzed',
	Reported: 'Reported'
};
export const LocationTypes = {
	Master: 'Master',
	Task: 'Task'
};

export const TAB_TITLE_SHORT = 'Scheduler';