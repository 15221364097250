import _ from 'lodash';

export const checkIsAdminUser = userData => {
	const adminRole = _.find(userData?.roles, role => role.idKey === 'schedu:manager');
	const isAdminUser = !_.isEmpty(adminRole);
	return isAdminUser;
};

export const checkIsTaskLead = userData => {
	const taskLead = _.find(userData?.roles, role => role.idKey === 'schedu:task_lead');
	const isTaskLead = !_.isEmpty(taskLead);
	return isTaskLead;
};

export const checkIsFlowRateItemPresent = task => {
	const flowItem = _.find(task.taskItems, i => i.itemName === 'Record Flow Rate');
	const isFlowRateItemPresent = !_.isEmpty(flowItem);
	return isFlowRateItemPresent;
};
