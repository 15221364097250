import QuickPanel from 'app/layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';

function RightSideLayout4() {
	return (
		<>
			<QuickPanel />
		</>
	);
}

export default memo(RightSideLayout4);
