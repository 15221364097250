import GpsService from '../../../../../services/gpsService/gpsLocationService';
import type {IGPSLocation} from '../../../../../services/gpsService/gpsLocation';

const MILES_CONST = 0.62137119;

export interface DistanceWithUnit {
	distance: string;
	value: string;
	unit: string;
}
export interface BasicSubLocation {
	id: string;
	clientId: number;
	locationId: number;
	labCode: string;
	projectId: number;
}

export interface BasicLocationDetails {
	stationName: string;
	stationNo: string;
	latitude: number;
	longitude: number;
	distance: number;
	distanceValue: number;
	distanceUnit: string;
	distanceValueUnit: string;
	samplingPoint: string;
	uniqueLocation: boolean;
}
export interface PreLocationDetails extends BasicLocationDetails {
	id: string;
}

export interface UniqueLocationDetails extends BasicLocationDetails {
	uniqueLocationId: number;
	id: number;
	locations: BasicSubLocation[];
}

export type MixedTypeLocation = UniqueLocationDetails | PreLocationDetails;

function transformDistance(distance: number) {
	let unit: string;
	let value: string;
	if ((!!distance || distance === 0) && distance !== Infinity) {
		if (distance >= 1) {
			unit = 'mi';
			const lessThan1K = distance < 1000;
			const valueToTransform = lessThan1K ? distance : distance / 1000;
			value =
				new Intl.NumberFormat('en-US', { maximumSignificantDigits: 1 }).format(valueToTransform) +
				(lessThan1K ? '' : 'k');
		} else {
			unit = 'ft';
			const convertedValueToFeet = distance * 5280;
			const lessThan1K = convertedValueToFeet < 1000;
			const valueToTransform = lessThan1K ? convertedValueToFeet : convertedValueToFeet / 1000;
			value =
				new Intl.NumberFormat('en-US', { maximumSignificantDigits: 1 }).format(valueToTransform) +
				(lessThan1K ? '' : 'k');
		}
	} else {
		unit = 'mi';
		value = '-';
	}
	return { unit, value };
}

function orderLocationsDetailsByDistance(locations: MixedTypeLocation[], currentLocation?: IGPSLocation): [] {
	let sortedLocation;
	if (!!currentLocation && !!locations) {
		const locationsDistance = locations.map(location => {
			if (!!location.latitude && !!location.longitude) {
				location.distance = GpsService.distanceBetweenTwoGPSLocations(currentLocation, {
					latitude: location.latitude,
					longitude: location.longitude
				});

				location.distance = Number.isNaN(location.distance) ? Infinity : location.distance * MILES_CONST;
			} else {
				location.distance = Infinity;
			}
			location.distanceValue = transformDistance(location.distance).value;
			location.distanceUnit = transformDistance(location.distance).unit;
			location.distanceValueUnit = `${location.distanceValue} ${location.distanceUnit}`;
			if (!location.stationNo) {
				location.stationNo = '';
			}
			return location;
		});
		sortedLocation = locationsDistance
			.slice()
			.sort((w1, w2) => (w1.distance > w2.distance ? 1 : w2.distance > w1.distance ? -1 : 0));
	} else {
		sortedLocation = locations;
	}
	return sortedLocation;
}

export function filterLocationByProject(locations, project) {
	return locations.filter(location => {
		if (location.imported) {
			const uniqueLocation = location;
			if (uniqueLocation.locations) {
				return uniqueLocation.locations.find(uni => {
					if (`${uni.labCode}-${uni.projectId}` === project) {
						return uni;
					}
					return null;
				});
			}
		} else if (location.labProjectId && location.labProjectId === project) {
			return location;
		}
		return null;
	});
}

export class LocationService {
	initLocations = async (locations): MixedTypeLocation[] => {
		let gpsLocation: IGPSLocation;
		const all: MixedTypeLocation[] = locations;
		try {
			await GpsService.getCurrentLocation().then(res => {
				gpsLocation = res;
			});
		} catch (e) {
			gpsLocation = null;
		}

		return orderLocationsDetailsByDistance(all, gpsLocation);
	};
}

const instance = new LocationService();

export default instance;
