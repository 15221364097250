import layout1 from 'app/layouts/layout1/Layout1';
import layout2 from 'app/layouts/layout2/Layout2';
import layout3 from 'app/layouts/layout3/Layout3';
import layout4 from 'app/layouts/layout4/Layout4';

const FuseLayouts = {
	layout1,
	layout2,
	layout3,
	layout4
};

export default FuseLayouts;
