import i18next from 'i18next';
import en from './navigation-i18n/en';

i18next.addResourceBundle('en', 'navigation', en);

const navigationConfig = [
	// {
	// 	id: 'file-manager',
	// 	title: 'File Manager',
	// 	translate: 'FILE_MANAGER',
	// 	type: 'group',
	// 	icon: 'snippet_folder',
	// 	url: '/apps/file-manager/my-files',
	// 	children: [
	// 		{
	// 			id: 'my-files',
	// 			title: 'My Files',
	// 			type: 'item',
	// 			url: '/apps/file-manager/my-files'
	// 		},
	// 		{
	// 			id: 'upload-file',
	// 			title: 'Upload Files',
	// 			type: 'item',
	// 			url: '/apps/file-manager/upload-file'
	// 		},
	// 		{
	// 			id: 'file-templates',
	// 			title: 'File Templates',
	// 			type: 'item',
	// 			url: '/apps/file-manager/file-templates-list'
	// 		}
	// 	]
	// },
	// {
	// 	id: 'locations',
	// 	title: 'Locations',
	// 	translate: 'LOCATIONS',
	// 	type: 'group',
	// 	icon: 'location_on',
	// 	url: '/apps/locations/view-locations',
	// 	children: [
	// 		{
	// 			id: 'view-locations',
	// 			title: 'View Locations',
	// 			type: 'item',
	// 			url: '/apps/locations/view-locations'
	// 		}
	// 	]
	// },
	// {
	// 	id: 'equipment',
	// 	title: 'Equipment',
	// 	translate: 'EQUIPMENT',
	// 	type: 'group',
	// 	icon: 'construction',
	// 	url: '/apps/equipment/home',
	// 	children: [
	// 		{
	// 			id: 'manage-equipment',
	// 			title: 'Manage Equipment',
	// 			type: 'item',
	// 			url: '/apps/equipment/home'
	// 		}
	// 	]
	// },
	// {
	// 	id: 'allocation-manager',
	// 	title: 'Allocation Manager',
	// 	translate: 'ALLOCATION_MANAGER',
	// 	type: 'group',
	// 	icon: 'device_hub',
	// 	url: '/apps/allocation-manager/systems',
	// 	children: [
	// 		{
	// 			id: 'allocation_systems',
	// 			title: 'Allocation Systems',
	// 			type: 'item',
	// 			url: '/apps/allocation-manager/systems'
	// 		},
	// 		{
	// 			id: 'allocation_systems_setup',
	// 			title: 'Allocation System Setup',
	// 			type: 'item',
	// 			url: '/apps/allocation-manager/allocation-system-setup'
	// 		}
	// 	]
	// },
	{
		id: 'task-view',
		title: 'Task View',
		type: 'group',
		icon: 'assignment',
		url: '/main/task-board/task-view',
		mobileView: true
	},
	{
		id: 'reoccurring-view',
		title: 'Reoccurring View',
		type: 'group',
		icon: 'group_work',
		url: '/main/task-board/reoccurring-view',
		mobileView: false
	},
	{
		id: 'daily-planner',
		title: 'Daily Planner',
		type: 'group',
		icon: 'assignment_ind',
		url: '/main/task-board/daily-planner',
		mobileView: true
	},
	{
		id: 'routes',
		title: 'Routes',
		type: 'group',
		icon: 'alt_route',
		url: '/main/task-board/routes',
		auth: ['schedu:manager', 'schedu:task_lead'],
		end: true,
		mobileView: true
	},
	// COMMENT TIME & MILEAGE IN PROD
	// {
	// 	id: 'logistics',
	// 	title: 'Time & Mileage',
	// 	type: 'group',
	// 	icon: 'local_shipping',
	// 	url: '/main/task-board/logistics',
	// 	end: true,
	// 	mobileView: true
	// }
];

export default navigationConfig;
