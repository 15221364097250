import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {unzip} from 'react-zlib-js';
import { Buffer } from 'buffer';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {GET_TASK_LOCATIONS, ADD_TASK_LOCATIONS} from './urls';
// eslint-disable-next-line import/no-cycle
import { setReloadGrid } from '../taskBoardGridSlice';

export const getTaskLocations = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getTaskLocations',
	async (params, thunkApi) => {
		try {
			const taskLocations = (await axios.get(`${BACKEND_SCHEDULER_URL}${GET_TASK_LOCATIONS}`)).data;
			return taskLocations;
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const CreateTaskLocation = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createTaskLocation',
	async (params, thunkApi) => {
		try {
			const taskLocation = (await axios.post(`${BACKEND_SCHEDULER_URL}${ADD_TASK_LOCATIONS}`, params)).data;
			thunkApi.dispatch(getTaskLocations());
			thunkApi.dispatch(showMessage({message: 'Location created Successfully', variant: 'success'}));
			return taskLocation;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const UpdateTaskLocation = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateTaskLocation',
	async (params, thunkApi) => {
		try {
			const response = await axios.put(`${BACKEND_SCHEDULER_URL}${ADD_TASK_LOCATIONS}/${params.id}`, params);
			const taskLocation = await response.data;
			thunkApi.dispatch(getTaskLocations());
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(showMessage({message: 'Location updated Successfully', variant: 'success'}));
			return taskLocation;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);


export const getMasterLocations = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getMasterLocations',
	async (params, thunkApi) => {
		try {
			// const masterLocations = (await axios.get(`${process.env.REACT_APP_MDM_API_URL}${MASTER_LOCATION}`, {
			// 	headers: {
			// 	  'x-api-key':`${process.env.REACT_APP_MDM_X_API_KEY}`
			// 	}
			// })).data;
			// let locData = await processMasterLocationBuffer(masterLocations);
			// locData = JSON.parse(locData?.toString());
			// return locData;
			return []; // commented the above lines until client based location filtered in MasterLocation
		} catch (err) {
			console.warn(err);
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

const processMasterLocationBuffer = async input => {
	const zippedBuffer = Buffer.from(input, 'base64');
	return new Promise((resolve, reject) =>
		unzip(zippedBuffer, (err, data) => {
			if (err) {
				reject(err);
			}
			resolve(data);
		})
	);
};
