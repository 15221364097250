import FuseShortcuts from '@fuse/core/FuseShortcuts';
import FuseSidePanel from '@fuse/core/FuseSidePanel';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { ThemeProvider } from 'styled-components';

function LeftSideLayout4(props) {

	const theme = useSelector(selectMainThemeDark);
	const useStyles = makeStyles(t => ({
		root: {
			backgroundColor: theme.palette.background.primary
		}
	}));
	const classes = useStyles(props);

	return (

		<>
			<ThemeProvider theme={theme}>
				<FuseSidePanel className={clsx(classes.root, 'shadow-md')}>
					<div className={clsx(classes.reactBadge, 'react-badge flex items-center py-8 px-8 rounded')}>
						<img className="logo-icon" src="assets/images/logos/spl-logo-only.png" alt="logo" />
					</div>
					<FuseShortcuts className="py-16 px-8" variant="vertical" />
				</FuseSidePanel>
			</ThemeProvider>
		</>

		// <>
		// 	<FuseSidePanel>
		// 		<FuseShortcuts className="py-16 px-8" variant="vertical" />
		// 	</FuseSidePanel>
		// </>
	);
}

export default memo(LeftSideLayout4);
