import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {TASK_CLIENTS} from './urls';
// eslint-disable-next-line import/no-cycle
import {getTaskLocations} from './taskLocationApi';
import { setReloadGrid } from '../taskBoardGridSlice';

export const getTaskClient = createAsyncThunk('taskBoardApp/taskBoardGrid/getTaskClient', async (params, thunkApi) => {
	try {
		const taskCustomers = (await axios.get(`${BACKEND_SCHEDULER_URL}${TASK_CLIENTS}`)).data;
		return taskCustomers;
	} catch (err) {
		return thunkApi.rejectWithValue('Something went wrong. Please try again!');
	}
});
export const createTaskClient = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createTaskClient',
	async (params, thunkApi) => {
		try {
			const taskClient = (await axios.post(`${BACKEND_SCHEDULER_URL}${TASK_CLIENTS}`, params)).data;
			thunkApi.dispatch(showMessage({message: 'Task client created successfully', variant: 'success'}));
			thunkApi.dispatch(getTaskClient());
			return taskClient;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateTaskClient = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateTaskClient',
	async (params, thunkApi) => {
		try {
			const taskCustomer = (await axios.put(`${BACKEND_SCHEDULER_URL}${TASK_CLIENTS}/${params.id}`, params)).data;
			thunkApi.dispatch(getTaskClient());
			thunkApi.dispatch(getTaskLocations());
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(showMessage({message: 'Task client updated successfully', variant: 'success'}));
			return taskCustomer;
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);
