import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';

// App specifics
import appsConfigs from '../main/apps/appsConfigs';
import RegisterPageConfig from '../main/pages/auth/register/RegisterPageConfig';
import LoginPageConfig from '../main/pages/auth/login/LoginPageConfig';

const routeConfigs = [...appsConfigs];

const routes = [
	// if you want to make whole app auth protected by default change defaultAuth for example:
	// ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
	// The individual route configs which has auth option won't be overridden.
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin', 'staff', 'user','analyst']),
	{
		path: '*',
		component: () => <Redirect to="/main/task-board" />
	},
];

export default routes;
