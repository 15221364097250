import {LocationTypes} from 'app/main/constants/generalConstants';
import _ from 'lodash';

export const textSortFn = (a, b, field = null) => {
	const nameA = field ? a[field]?.toUpperCase() : a?.toUpperCase(); // ignore upper and lowercase
	const nameB = field ? b[field]?.toUpperCase() : b?.toUpperCase(); // ignore upper and lowercase

	// sort in an ascending order
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}

	// names must be equal
	return 0;
};

export default function processTaskLocation(masterLocations, taskLocations) {
	const locations = [];
	_.forEach(taskLocations, tLoc => {
		if (tLoc.locName) {
			locations.push({
				id: tLoc.id,
				type: LocationTypes.Task,
				name: tLoc.locName,
				addressLine1: tLoc.addressLine1,
				addressLine2: tLoc.addressLine2,
				city: tLoc.city,
				zipcode: tLoc.zipcode,
				county: tLoc.county,
				state: tLoc.state,
				latitude: tLoc.latitude,
				longitude: tLoc.longitude,
				taskClientId: tLoc.taskClientId,
				taskClientName: tLoc.taskClientName
			});
		}
	});
	_.forEach(masterLocations, mLoc => {
		if (mLoc.locName) {
			locations.push({
				id: mLoc.id,
				type: LocationTypes.Master,
				name: mLoc.locName,
				addressLine1: mLoc.address,
				county: mLoc.addrCounty,
				state: mLoc.addrState,
				latitude: mLoc.coordLonLat?.lat,
				longitude: mLoc.coordLonLat?.lng
			});
		}
	});
	locations.sort((a, b) => textSortFn(a, b, 'name'));
	return locations;
}

export const processTaskAddress = locData => {
	if (!locData) return '';
	const data = [];
	const addrKeys = ['state', 'county', 'city', 'addressLine1', 'addressLine2'];
	_.forEach(addrKeys, key => {
		if (locData[key]) {
			data.push(locData[key]);
		}
	});
	return data.join(', ');
};
